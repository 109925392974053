@tailwind base;
@tailwind components;
@tailwind utilities;

::-moz-selection {
  /* Code for Firefox */
  color: white;
  background: red;
}

::selection {
  color: white;
  background: red;
}
